import React from "react";
import Copyright from "containers/visuella/Copyright";

const CopyrightPage = () => {
  return (
    <>
      <Copyright />
    </>
  );
};
export default CopyrightPage;
